// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("C:\\Users\\olear\\github\\roadgauge-lp\\src\\pages\\404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("C:\\Users\\olear\\github\\roadgauge-lp\\src\\pages\\about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("C:\\Users\\olear\\github\\roadgauge-lp\\src\\pages\\contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("C:\\Users\\olear\\github\\roadgauge-lp\\src\\pages\\index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-integrations-slack-js": () => import("C:\\Users\\olear\\github\\roadgauge-lp\\src\\pages\\integrations\\slack.js" /* webpackChunkName: "component---src-pages-integrations-slack-js" */),
  "component---src-pages-privacy-js": () => import("C:\\Users\\olear\\github\\roadgauge-lp\\src\\pages\\privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-terms-js": () => import("C:\\Users\\olear\\github\\roadgauge-lp\\src\\pages\\terms.js" /* webpackChunkName: "component---src-pages-terms-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "C:\\Users\\olear\\github\\roadgauge-lp\\.cache\\data.json")

